import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/system-crm-w-zarzadzaniu-vlog.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    zdjecie1: file(relativePath: { eq: "szanse-sprzedazy-crm.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Zarzadzaniekampaniamicrm = ({ data }) => {
  return (
    <Artykul
      title="Zarządzanie kampaniami w CRM"
      keywords={["zarządzanie kampaniami crm"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Zarządzanie kampaniami, CRM i szanse sprzedaży"
      metaTitle="Zarządzanie kampaniami, CRM i szanse sprzedaży"
      metaDescription="System CRM może zoptymalizować Twój proces planowania kampanii - sprawdź, jak! ✅ Zarządzanie kampaniami CRM ✅ 
      Zarządzanie szansami sprzedaży ✅ Video 2020"
    >
      <br />
      <p>
        W poniższym wpisie naszego vloga opowiemy Wam, na czym polega
        zarządzanie kampaniami. CRM zapewnia nie tylko łatwą kontrolę nad
        kontaktami z klientami, ale także umożliwia prowadzenie szerokiej gamy
        kampanii sprzedażowych. W tym artykule opiszemy, czym jest kampania
        sprzedażowa, jak skutecznie zarządzać kampaniami oraz jakie są korzyści
        wynikające z wizualizacji ich w widoku Kanban.
      </p>
      <br />
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/LLvCdR5OL-I"
        allowFullScreen
        ratio="16:9"
      />
      <br />
      <h2>Kampanie sprzedaży - co to takiego?</h2>
      <p>
        Internet stał się kluczowym medium wspierającym sprzedaż, dlatego dobrze
        jest wiedzieć, jak tworzyć kampanie sprzedażowe i zarządzać nimi w tym
        środowisku.
      </p>

      <p>
        Co dokładnie nazywa się „kampanią sprzedażową”? Szereg konkretnych
        działań, które przyczyniają się do osiągania celów komercyjnych, takich
        jak zwiększenie liczby klientów i generowanie sprzedaży.
      </p>

      <p>
        Kampanie sprzedażowe można podzielić na inbound oraz outbound. W tym
        pierwszym przypadku organizujemy swój biznes w ten sposób, by to klient
        się do nas zgłaszał. W drugim to nasi handlowcy w sposób aktywny
        poszukują kolejnych kontrahentów. Kampanie mogą być szybkimi akcjami
        podzielonymi na kilka prostych etapów, ale mogą też być bardziej
        długoterminowe i zakrojone na szerszą skalę. W tym drugim przypadku
        kampanie są zazwyczaj nieco bardziej złożone, dlatego składają się z
        większej ilości etapów. Mogą nimi być, dla przykładu, projektowanie
        jakiegoś rozwiązania, jego wdrożenie czy też przeprowadzenie serii
        szkoleń.
      </p>

      <p>
        Istnieje wiele pomysłów na kampanie sprzedażowe. Wszystko zależy od
        branży, do której należy Twoja firma i kroków, które musisz podjąć, aby
        osiągnąć swoje cele.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz zarządzać kampaniami i szansami sprzedaży?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i poznać nowy sposób zarządzania kampaniami sprzedażowymi
            </Link>
          </div>
          </div>
    </section>
      <br />
      <h2>CRM a zarządzanie kampaniami</h2>
      <p>
        Zaletą organizowania kampanii przy użyciu systemu CRM jest to, że cały
        proces sprzedaży jest prezentowany w nim na jednym widoku. Wszystkie
        informacje potrzebne do zamknięcia transakcji znajdują się w jednym
        miejscu. Handlowcy nie marnują czasu na przełączanie się między
        kolejnymi programami lub ich kartami, nie wyszukują danych w wielu
        e-mailach i mogą szybko i łatwo monitorować każdą otwartą szansę
        sprzedaży. Ponadto widok ten znacznie ułatwia zarządzanie kampanią,
        zapewniając przedstawicielom handlowym informacje o statusie
        zaplanowanych zadań związanych z konkretnymi transakcjami. Oczywiście
        CRM umożliwia planowanie wielu kampanii jednocześnie, więc nie musisz
        się w żaden sposób ograniczać.
      </p>
      <br />
      <h2>Szanse sprzedaży w CRM</h2>
      <p>
        “Szansami sprzedaży” nazywamy pozyskany kontrahenta, który pasuje do
        profilu danego przedsiębiorstwa i został zaakceptowany do danej
        kampanii. Jako że udana sprzedaż bezpośrednio przekłada się na sukces
        przedsiębiorstwa, od prawidłowego opisu jej poszczególnych etapów zależy
        być albo nie być firmy. Zadanie to może wydawać się trudne, ale dzięki
        dobremu systemowi CRM można w prosty sposób zwizualizować sobie firmowe
        procesy sprzedażowe i rozpocząć ich optymalizację.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={["Widok szans sprzedaży w systemie CRM"]}
      />
      <p>
        Na wczesnych etapach przedstawiony w systemie CRM lejek sprzedaży jest
        najszerszy. Wynika to z faktu, że zawiera on wszystkich potencjalnych
        klientów zainteresowanych naszą ofertą. Na przykład są to wszyscy
        ludzie, którzy odwiedzili naszą witrynę internetową. Ścieżka zwęża się z
        każdym poziomem, więc jest coraz mniej potencjalnych klientów. Odrzucamy
        bowiem tych, którzy nie chcą korzystać z naszej oferty, pozostawiając
        tylko tych, z którymi współpraca wciąż ma jakiś potencjał.
      </p>

      <p>
        Na kolejnych etapach zostawiamy więc klientów, z którymi mamy dobry
        kontakt oraz szansę na pozytywne zamknięcie transakcji. Celem każdego
        przedsiębiorstwa jest przeniesienie największej liczby klientów od
        początku do końca lejka. Można to osiągnąć poprzez odpowiednią analizę
        potrzeb i opracowanie właściwej strategii marketingowej dla każdego
        etapu.
      </p>
      <br />
      <h2>Na zakończenie</h2>
      <p>
        Wszystko to oznacza, że ​​wprowadzenie kampanii i systemu szans
        sprzedaży może zostać bardzo ważnym aspektem działalności Twojej firmy.
        I to bez względu na branżę, w której się specjalizujesz, bez względu na
        liczbę pracowników czy kontrahentów. Zwiększenie sprzedaży, poprzez
        monitorowanie, analizowanie i optymalizowanie procesów - wszystko to
        umożliwia sprawne zarządzanie kampaniami. CRM jest zaś do tego bardzo
        wygodnym narzędziem.
      </p>

      <p>
        Nasze systemy CRM są całkowicie konfigurowalne, dzięki czemu mogą być
        dopasowane do potrzeb każdego przedsiębiorstwa, niezależnie od jego
        rozmiarów. Znajdują swoje zastosowanie wszędzie tam, gdzie istotne jest
        poprawienie wyników sprzedażowych. Można śmiało powiedzieć, że system
        CRM w rękach wydajnych i wykwalifikowanych handlowców jest kluczowym
        narzędziem rozwoju każdej firmy.
      </p>

      <br />
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz zarządzać kampaniami i szansami sprzedaży?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i poznać nowy sposób zarządzania kampaniami sprzedażowymi
            </Link>
          </div>
          </div>
    </section>
      <p>
        Jeżeli chcesz kontynuować zgłębianie wiedzy na temat efektywnej pracy na
        systemach CRM to zapraszamy tutaj:
      </p>
      <p>
        <ul>
          <li>
            <Link to="/zarzadzanie-zespolem-rozproszonym">
              Zarządzanie rozproszonym zespołem sprzedażowym w czasie kryzysu
            </Link>
          </li>
          <li>
            <Link to="/zarzadzanie-zespolem-marketingu-crm">
              Zarządzanie pracą zespołu marketingu w systemie CRM
            </Link>
          </li>
          <li>
            <Link to="/lejek-sprzedazy-crm">
              Lejek sprzedaży - wsparcie dla działu handlowego
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Zarzadzaniekampaniamicrm;
